import React from "react";
import { graphql } from "gatsby";
import HomeScreen from "../components/evento/HomeScreen";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HomeScreen2 from "../components/evento/HomeScreen2";

const JuevesCultural = ({ location, data }) => {


  const params = (location && location.search )?new URLSearchParams(location.search):null;
  const areaParam = (params && params.get("area") ) ? params.get("area").split('|'):[] ;
  const tipoParam = (params &&   params.get("tipo")) ? params.get("tipo").split('|'):[] ;
  const etiquetaParam = (params &&   params.get("etiqueta")) ? params.get("etiqueta").split('|'):[] ;

  console.log(`tipoParam : ${JSON.stringify(tipoParam)} , areaParam : ${JSON.stringify(areaParam)} , etiquetaParam : ${JSON.stringify(etiquetaParam)}`);
  //let next2 = next;
  let areasTematicas = data.allStrapiAgendaMasterAreaTematica.edges.map(
    (edge) => edge.node
  );
  let tiposEvento = data.allStrapiAgendaMasterTipoEvento.edges.map(
    (edge) => edge.node
  );
  let eventos = data.allApiExternaEventosNext.edges.map((edge) => edge.node);
 
  
  if (tipoParam.length>0 )
  {
    console.log(` dentro de filter  elemento tipoParam 1`);
    eventos = eventos.filter((x) => {

      console.log(` dentro de filter  elemento tipoParam 2`);
  
      return (x.tipo_evento && (tipoParam.length === 0 || tipoParam.indexOf (x.tipo_evento.Nombre) >-1)  )
    }); 

  }
  if (areaParam.length>0 )
  {
    console.log(` dentro de filter  areaParam 1`);
      
    eventos = eventos.filter((x) => {

      console.log(` dentro de filter  areaParam 2`);
      
  
      return (x.area_tematicas &&  (x.area_tematicas.filter((y)=>(areaParam.indexOf (y.Nombre) >-1)).length>0 ) )
    }); 
  }

  
      

  if (etiquetaParam.length>0 )
  {
        console.log(` dentro de filter  etiquetaParam 1`);

    eventos = eventos.filter((x) => {
      console.log(` dentro de filter  etiquetaParam 2 `);
      const fnTieneElementos = (y) =>{

        const boolEncontrado = etiquetaParam.indexOf (y.Nombre) >1;
        if (boolEncontrado) console.log(`  etiqueta encontrada Nombre: ${y.Nombre} evento: ${x.slug}`); 


        return boolEncontrado
      }
      return (x.agenda_master_etiquetas &&  (x.agenda_master_etiquetas.filter( fnTieneElementos ).length>0 ) );
    }); 

  }

  console.log(` home.js linea 3  cantidad eventos: ${eventos.length}`);


  const resultData = {
    next: eventos,
    areasTematicas: areasTematicas,
    tipoEvento: tiposEvento,
    variablesEntorno: {
      AGENDA_APP_SITE: data.site.siteMetadata.AGENDA_APP_SITE,
    },
    location: location
  };

  return (
    <Layout>
      <SEO title="PUCP" pathname="/home" />
      <HomeScreen2 resultData={resultData} />
    </Layout>
  );
};
export default JuevesCultural;

export const pageQuery = graphql`
query JuevesCulturalnQuery {
  allStrapiAgendaMasterAreaTematica {
    edges {
      node {
        Nombre
        id
        strapiId
      }
    }
  }
  allStrapiAgendaMasterTipoEvento {
    edges {
      node {
        Nombre
        TipoEvento
        id
        strapiId
      }
    }
  }
  site {
    siteMetadata {
      title
      AGENDA_APP_SITE
    }
  }
  allApiExternaEventosNext(filter: {agenda_master_etiquetas: { elemMatch: { Nombre:{eq: "Jueves Cultural"}} }   }  ) {
    edges {
      node {
        slug
        EventoDestacado
        ImagenDestacada {
          alternativeText
          alternative_id
          ext
          name
          mime
          height
          provider
          size
          url
          width
        }
        Titulo
        Fechas {
          Fecha
          Fin
          Inicio
          _xcomponent
          alternative_id
        }
        WorkFlow
        alternative_id
        area_tematicas {
          Nombre
          alternative_id
        }
        tipo_evento {
          Nombre
          TipoEvento
          alternative_id
        }
        id
        Lugar {
          DescripcionLink
          DescripcionLugar
          URL
          Ubicacion
          agenda_master_lugar_pucp {
            Nombre
            alternative_id
          }
          alternative_id
          _xcomponent
        }
        agenda_master_etiquetas {
          Nombre
          alternative_id
        }
      }
    }
  }
}
`;