// import { Link } from "gatsby"
import React, { useState } from "react";
import FiltroFecha from "./filtroFecha";
import FiltroTipo from "./filtroTipo";
import FiltroArea from "./filtroArea";
import CarouselDestacado from "./carouselDestacado";
import moment from "moment";
import "moment/locale/es";
import GrillaEvento from "./grillaEvento";

const HomeScreen2 = ({resultData }) => {
  moment.locale("es");

  const { areasTematicas, next, tipoEvento, variablesEntorno ,etiquetaParam } = resultData;


  const params = (resultData.location && resultData.location.search )?new URLSearchParams(resultData.location.search):null;
  const areaParam = (params && params.get("area") ) ? params.get("area").split('|'):[] ;
  const tipoParam = (params &&   params.get("tipo")) ? params.get("tipo").split('|'):[] ;

  /*
  const params = (resultData.location && resultData.location.search )?new URLSearchParams(resultData.location.search):null;
  
  const areaParam = (params && params.get("area") ) ? params.get("area").split('|'):[] ;
  const tipoParam = (params &&   params.get("tipo")) ? params.get("tipo").split('|'):[] ;
  const etiquetaParam = (params &&   params.get("etiqueta")) ? params.get("etiqueta").split('|'):[] ;
  console.log(`tipoParam : ${JSON.stringify(tipoParam)} , areaParam : ${JSON.stringify(areaParam)}`);
  let next2 = next;

  if (tipoParam.length>0 )
  {
    next2 = next2.filter((x) => {

      console.log(` dentro de filter  elemento tipoParam :${JSON.stringify(x.tipo_evento)}`);
  
      return (x.tipo_evento && (tipoParam.length === 0 || tipoParam.indexOf (x.tipo_evento.Nombre) >-1)  )
    }); 

  }
  if (areaParam.length>0 )
  {
    next2 = next2.filter((x) => {

      console.log(` dentro de filter  areaParam :${JSON.stringify(x.area_tematicas)}`);
      
  
      return (x.area_tematicas &&  (x.area_tematicas.filter((y)=>(areaParam.indexOf (y.Nombre) >-1)).length>0 ) )
    }); 
  }
  if (etiquetaParam.length>0 )
  {
    next2 = next2.filter((x) => {

      console.log(` dentro de filter  etiquetaParam :${JSON.stringify(x.agenda_master_etiquetas)}`);
      
  
      return (x.agenda_master_etiquetas &&  (x.agenda_master_etiquetas.filter((y)=>(etiquetaParam.indexOf (y.Nombre) >-1)).length>0 ) )
    }); 

  }

  */

  

 

  const [state, setState] = useState({
    tiposSeleccionados: [...tipoParam],
    areasSeleccionadas: [...areaParam],
    fechasSeleccionadas: false,
    //eventos: [...next],
    eventos: [...next],
    filtradoXArea: [],
    filtradoXTipo: [],
    filtradoXFecha: [],
    errorFechas: false,
    hoy: false,
    semana: false,
    semanaSiguiente: false,
    gruposMostrados: 1,
  });

  const [popUp, setPopUp] = useState({
    fecha: false,
    tipo: false,
    area: false,
  });

  const filtradoCruzado = (aux) => {
    let newEventos = [];

    if (
      aux.filtradoXArea.length !== 0 &&
      aux.tiposSeleccionados.length !== 0 &&
      aux.fechasSeleccionadas
    ) {
      let cruce1 = [];
      aux.filtradoXArea.forEach((fa) => {
        aux.filtradoXTipo.forEach((ft) => {
          if (ft.id === fa.id) {
            cruce1.push(fa);
          }
        });
      });
      let cruce2 = [];
      cruce1.forEach((evento) => {
        aux.filtradoXFecha.forEach((ff) => {
          if (ff.id === evento.id) {
            cruce2.push(evento);
          }
        });
      });
      newEventos = cruce2;
    } else if (
      aux.filtradoXArea.length !== 0 &&
      aux.tiposSeleccionados.length !== 0
    ) {
      let cruce1 = [];
      aux.filtradoXArea.forEach((fa) => {
        aux.filtradoXTipo.forEach((ft) => {
          if (ft.id === fa.id) {
            cruce1.push(fa);
          }
        });
      });
      newEventos = cruce1;
    } else if (aux.tiposSeleccionados.length !== 0 && aux.fechasSeleccionadas) {
      let cruce1 = [];

      aux.filtradoXTipo.forEach((ft) => {
        aux.filtradoXFecha.forEach((ff) => {
          if (ff.id === ft.id) {
            cruce1.push(ft);
          }
        });
      });

      newEventos = cruce1;
    } else if (aux.filtradoXArea.length !== 0 && aux.fechasSeleccionadas) {
      let cruce1 = [];

      aux.filtradoXArea.forEach((fa) => {
        aux.filtradoXFecha.forEach((ff) => {
          if (ff.id === fa.id) {
            cruce1.push(fa);
          }
        });
      });

      newEventos = cruce1;
    } else if (aux.filtradoXArea.length !== 0) {
      newEventos = aux.filtradoXArea;
    } else if (aux.tiposSeleccionados.length !== 0) {
      newEventos = aux.filtradoXTipo;
    } else if (aux.fechasSeleccionadas) {
      newEventos = aux.filtradoXFecha;
    } else {
      newEventos = [...next];
    }
    setState({
      ...aux,
      eventos: [...newEventos],
    });
  };



  return (
    <>
      <CarouselDestacado eventos={next} variablesEntorno={variablesEntorno} />

      <div className="section-events">
        <div className="container-1200 w-container">
          <div className="cont-head-events">
            <div className="div-50 _w-f">
              <h2 className="h2-title-home">Próximos eventos</h2>
            </div>
            <div className="div-50 _w-f">
              <div className="cont-btn-filtros">
                <FiltroFecha
                  eventos={next}
                  popUp={popUp.fecha}
                  setPopUp={setPopUp}
                  state={state}
                  filtradoCruzado={filtradoCruzado}
                />

                <FiltroTipo
                  eventos={next}
                  filtradoCruzado={filtradoCruzado}
                  popUp={popUp.tipo}
                  setPopUp={setPopUp}
                  state={state}
                  setState={setState}
                  tiposEvento={tipoEvento}
                />

                <FiltroArea
                  eventos={next}
                  filtradoCruzado={filtradoCruzado}
                  popUp={popUp.area}
                  setPopUp={setPopUp}
                  state={state}
                  setState={setState}
                  areasTematicas={areasTematicas}
                />
              </div>
            </div>
          </div>
          <div className="line-white blue"></div>

          <GrillaEvento
            state={state}
            setState={setState}
            variablesEntorno={variablesEntorno}
          />
        </div>
      </div>
    </>
  );
};

export default HomeScreen2;